import React, { useState } from 'react';
import { Typography, Stack, useMediaQuery, useTheme, TextField, Link, Grid } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Button } from 'react-bootstrap';
import { useApi } from 'hooks/useAPI';
import { registerUser, sendEmailOtp, sendOTP, verifyOtp, verifyPhoneNumber, verifyEmail } from 'services/apis/newApis';

import { useSnackbar } from 'notistack';


export default function SignUp() {
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState('');

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [emailotp, setEmailOtp] = useState('');
  const [otp, setOtp] = useState('');
  const [mpin, setMpin] = useState('');

  const [otpFlag, setOtpFlag] = useState(true);
  const [emialOtpFlag, setEmailOtpFlag] = useState(true);

  const [isOTPVerified, setOTPVerfied] = useState(false);
  const [isEmailOtpVerified, setEmailOTPVerified] = useState(false);

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [sendOTPLoading, sendOTPApi] = useApi(sendOTP);
  const [setVerifyLoading, verifyOtpApi] = useApi(verifyOtp);
  
  const [setEmailOtpLoading, sendOTPEmailApi] = useApi(sendEmailOtp);
  const [loading, callAPI] = useApi(registerUser);

  const [verifyPhoneNumberLoding, verifyPhoneNumberApi] = useApi(verifyPhoneNumber);
  const [verifyEmailLoading, verifyEmailApi] = useApi(verifyEmail);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Handle phone input change
  const handlePhoneChange = (newPhone) => {
    setPhone(newPhone);
  };

  const handleChangeOtp = (newValue) => {
    setOtp(newValue);
  };

  const handleChangeEmailOtp = (newValue) => {
    setEmailOtp(newValue);
  };

  const handleChangeMPIN = (newValue) => {
    setMpin(newValue);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // Validate inputs
    let flag = true;
    let reason = '';

    if (name) {
    } else {
      reason = 'please fill your name';
      flag = false;
    }

    if (isOTPVerified) {
    } else {
      reason = 'please Verify your Phone Number';
      flag = false;
    }

    if (isEmailOtpVerified) {
    } else {
      reason = 'please Verify your email';
      flag = false;
    }

    if (mpin.length === 4) {
    } else {
      reason = 'please set your mpin';
      flag = false;
    }

    if (flag === false) {
      enqueueSnackbar(reason, { variant: 'warning' });
    } else {
      enqueueSnackbar('User Register Successfully', { variant: 'success' });

      callAPI({
        data: {
          name: name,
          email: email,
          phone: phone,
          mpin: mpin,
        },
      })
        .then((res) => {
          window.location.replace("/");
          enqueueSnackbar(res.Message, { variant: "success" });
        })
        .catch((err) => {
          // console.log(err);
          enqueueSnackbar(err, { variant: "error" });
          // setStep("verifyOTP");
        });

    
    }
  };



  const verifyPhone = async() => {
    let flag = await verifyPhoneNumberApi({
        data: {
          phone: phone,
        },
      })
        .then((res) => {
          enqueueSnackbar('OTP send Successfully !!');
          setOtpFlag(false);
          return true;
        })
        .catch((err) => {
          enqueueSnackbar('Phone Number Already register !!');
          console.log("error",err);
          return false;
        });
        return flag;
  }

  const verifyEmailExist = async() => {

    let flag = await  verifyEmailApi({
        data: {
          email: email,
        },
      })
        .then((res) => {
            console.log(res,"lllpp");
            return true;
        })
        .catch((err) => {
          enqueueSnackbar('Email is already register',{variant:"warning"});
          console.log("error",err);
          return false;
        });

   
        return flag;
  }


  const handlePhoneVerification = async() => {
    let number = await verifyPhone();
    // if(number){
    setIsPhoneVerified(true);
    // } else{
        // enqueueSnackbar("Number already registered !!", {variant:"warning"});
    // }
  };

  const handleVerifiyOtp = () => {
    verifyOtpApi({
      data: {
        phone: phone,
        otp: otp,
      },
    })
      .then((res) => {
        // console.log('otp verified Successfully !!', res);
        enqueueSnackbar('otp verified Successfully !!');
        setOTPVerfied(true);
        localStorage.setItem('Authorization', 'Bearer ' + res.token);
        window.location.replace('/');
      })
      .catch((err) => {
        enqueueSnackbar('Wrong OTP Please try again !!');
      });
    };

  const handleVerifiyEmailOtp = () => {
    verifyOtpApi({
      data: {
        phone: email,
        otp: emailotp,
      },
    })
      .then((res) => {
        // console.log('otp verified Successfully !!', res);
        enqueueSnackbar('otp verified Successfully !!');
        // setOTPVerfied(true);
        setEmailOTPVerified(true);
      })
      .catch((err) => {
        enqueueSnackbar('Wrong OTP Please try again !!');
      });
      
  };

  const handleEmailVerification = async() => {
    sendOTPEmailApi({
      data: {
        to: email,
        from: 'support@chartinghq.com',
        subject: 'Your OTP Code',
        text: 'Your OTP code is',
        html: '<strong>Your OTP code is 123456</strong>',
      },
    })
      .then((res) => {
        // console.log('OTP send successfully on email !!');
        enqueueSnackbar('OTP send successfully on email !!');
        setEmailOtpFlag(false);
        setIsEmailVerified(true);
      })
      .catch((err) => {
        enqueueSnackbar('Not send');
      });
  };

  return (
    <>
      <Stack sx={{ marginBottom: '5vh' }}>
        <Typography variant="h4" sx={{ fontWeight: '700' }}>
          Sign Up
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: '400' }}>
          Please fill in this form to create an account!
        </Typography>
      </Stack>

      <Stack spacing={3}>
 

        {/* Phone input */}

        {otpFlag ? (
          <Grid container justifyContent={'space-between'} alignItems="center">
            <Grid item xs={12} >
              <MuiTelInput
                value={phone}
                onChange={handlePhoneChange}
                defaultCountry="IN" // Set default country to India
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={2}>
              {/* <Link
                component="button"
                variant="body2"
                onClick={handlePhoneVerification}
                sx={{
                  textAlign: 'right',
                  color: '#000',
                  cursor: 'pointer',
                }}
              >
                Verify Phone
              </Link> */}
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent={'space-between'} alignItems="center">
            {isOTPVerified ? (
              <>
                <MuiTelInput
                  value={phone}
                  disabled={true}
                  onChange={handlePhoneChange}
                  defaultCountry="IN" // Set default country to India
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000',
                      },
                    },
                  }}
                />
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <MuiOtpInput
                    value={otp}
                    onChange={handleChangeOtp}
                    length={6} // Customize based on MPIN length
                    sx={{
                      '& .MuiInputBase-input': {
                        fontSize: '1.2rem',
                        textAlign: 'center',
                        height: '0.8rem',
                      },
                      '& .MuiInput-root': {
                        marginLeft: '8px',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
         
                </Grid>
              </>
            )}
          </Grid>
        )}

        {/* MPIN input */}
        {/* <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            fontWeight="300"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            MPIN:&nbsp;
          </Typography>

          <MuiOtpInput
            value={mpin}
            onChange={handleChangeMPIN}
            length={4} // Customize based on MPIN length
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '1.2rem',
                textAlign: 'center',
                height: '1rem',
              },
              '& .MuiInput-root': {
                marginLeft: '8px',
              },
            }}
          />
        </Stack> */}

        {/* Submit button */}
        {otpFlag ? (
          <form
  onSubmit={(e) => {
    e.preventDefault(); // Prevent default form submission behavior
    handlePhoneVerification(); // Call your custom handler
  }}
>
  <Button
    type="submit" // Ensures compatibility with Enter (Windows) or Return (Mac)
    variant="outline-primary"
    style={{
      width: '100%',
      padding: '10px',
      fontWeight: '600',
      marginTop: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.2s ease-in-out',
      backgroundColor: '#000',
      color: '#fff',
    }}
  >
    Send OTP
  </Button>
</form>

        )
        :
        (
            <Button
            variant="outline-primary"
            style={{
              width: '100%',
              padding: '10px',
              fontWeight: '600',
              marginTop: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.2s ease-in-out',
              backgroundColor:"#000",
              color:"#fff",

            }}
            onClick={handleVerifiyOtp}
          >
            Verify otp
          </Button>
        )}

      </Stack>
    </>
  );
}
