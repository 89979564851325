import React from "react";
import Lottie from "lottie-react";
import * as loaderJson from "assets/others/Loader.json";

function Loader() {
  return (
    <div
      style={{
        position: "fixed", // Ensure it spans the full screen
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.9)", // Optional: Slight overlay
        zIndex: 9999, // Ensure it appears above other content
      }}
    >
      <Lottie
        animationData={loaderJson}
        loop={true}
        style={{
          width: 320,
          margin: "auto",
          color:"#000",
        }}
      />
    </div>
  );
}

export default Loader;
