const initialState = {
    state: { data: "dashBoard" },
  };

  export default function reducer(state = initialState, action) {
    switch (action.type) {
      case "STATEMANAGER":
        return {
          ...state,
          state: action.payload,
        };
      default:
        return state;
    }
  }
  