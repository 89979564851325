import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { AccountCircle, ExpandMore, Logout } from "@mui/icons-material";
  import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

//   import { menuOptionAction } from "redux/actions/menustate";
import { logoutUser, changeMpin, verifyMpin } from "services/apis/newApis";
import { useApi } from "hooks/useAPI";
import { updateProfile, generateAccessToken } from "services/apis/newApis"; // Make sure to import your API function
import CableIcon from '@mui/icons-material/Cable';
//   import PhoneNumberInput from "./../PhoneNumberInput";
//   import EmailInput from "./../EmailInput";
import { MuiOtpInput } from "mui-one-time-password-input";


function IconMenu() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange1 = (event) => {
        setSelectedValue(event.target.value);
    };



    const profileData = useSelector((state) => state?.userProfile?.profileData);
    const customLgDown = useMediaQuery(theme.breakpoints.down(1380));
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    const { enqueueSnackbar } = useSnackbar();

    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBrokerOpen, setDialogBrokerOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [mpin, setMpin] = useState();
    const [verifyMpinLoading, verifyMpinApi] = useApi(verifyMpin);
    const [mpinStatus, setMpinStatus] = useState(false);
    const [mpinFlag, setMpinFlag] = useState(true);
    const [loadingMpinApi, setMpinApi] = useApi(changeMpin);
    const [generateAccessTokenLoading, generateAccessTokenApi] = useApi(generateAccessToken);

    const [formData, setFormData] = useState({
        name: profileData?.name,
        phone: profileData?.phone,
        email: profileData?.email,
    });

    const updateEmail = useCallback((newEmail) => {
        setEmail(newEmail);
        setFormData({ ...formData, email: newEmail });
    }, []);


    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
        handleProfileClose();
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogBrokerOpen = () => {
        setDialogBrokerOpen(true);
    };

    const handleDialogBrokerClose = () => {
        setDialogBrokerOpen(false);
    };


    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    // const handleFileChange = (e) => {
    //   const { id } = e.target;
    //   const file = e.target.files[0];
    //   setFormData({ ...formData, [id]: file });
    // };

    function setMenuOption(data) {
        //   dispatch(
        //     menuOptionAction({
        //       data: data,
        //     })
        //   );

    }

    const openProfilePopover = Boolean(anchorEl);

    const profileId = openProfilePopover ? "simple-popover" : undefined;

    const profilePopoverMenu = () => {
        const profile = {
            title: (profileData?.name?.length > 0) ? profileData?.name : "Update Profile",
            icon: <AccountCircle />,
            onClick: handleDialogOpen,
        };
        const logout = {
            title: "Logout",
            icon: <Logout />,
            onClick: () => {
                logoutUser({})
                    .then((data) => {
                        localStorage.clear();
                        enqueueSnackbar("Logout Successful!", { variant: "success" });
                        window.location.reload();
                    })
                    .catch((err) => {
                        localStorage.clear();
                        enqueueSnackbar("Something went wrong", { variant: "error" });
                        window.location.reload();
                    });
            },
        };
        return mobile ? [profile, logout] : [profile, logout];
    };

    const [loading, callAPI] = useApi(updateProfile);

    const handleSubmit = () => {

        callAPI({ data: formData })
            .then(() => {
                enqueueSnackbar("Profile updated successfully!", {
                    variant: "success",
                });
                setDialogOpen(false);
            })
            .catch(() => {
                enqueueSnackbar("Failed to update profile", { variant: "error" });
            });
    };


    const handleSubmitBroker = () => {
        generateAccessTokenApi({ data: { userId: profileData?.userId, apiKey: formData?.apiKey, apiSecret: formData?.apiSecret } })
            .then(() => {
                enqueueSnackbar("Broker Connected Successfully !!", {
                    variant: "success",
                });
                setDialogBrokerOpen(false);
            })
            .catch(() => {
                enqueueSnackbar("Failed to Connect Broker ", { variant: "error" });
            });
    };

    const handleChangeMpin = (newValue) => {
        setMpin(newValue);
    };

    const verifyMpinFunction = async () => {
        verifyMpinApi({}).then((res) => {
            if (res === "update mpin") {
                setMpinStatus(true);
            } else {
                setMpinStatus(false);
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    const handleSetMpin = async () => {
        await setMpinApi({ data: { "mpin": mpin } }).then((res) => {
            enqueueSnackbar("MPIN Set Successfully", { variant: "success" });
            verifyMpinFunction();
            setMpinFlag(true);
        }).catch((err) => {
            enqueueSnackbar(`Failed to set MPIN ${err}`, { variant: "error" });
        });
    }

    useEffect(() => {
        verifyMpinFunction();
    }, []);


    const handleToggle = () => {
        setMpinFlag(false);
    };

    return (
        <>
            <Stack spacing={3} alignItems={"center"} direction={"row"}>
                <>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={mobile ? 1 : 2}
                        onClick={(e) => customLgDown && handleProfileClick(e)}
                    >


                        <Box>
                            <Avatar
                                onClick={(e) => customLgDown && handleProfileClick(e)}
                                alt={profileData?.name}
                                src={
                                    profileData?.ProfileImageLink
                                        ? profileData?.ProfileImageLink
                                        : "X"
                                }
                            />
                        </Box>
                        {!customLgDown && (
                            <>

                                {" "}

                                <Box>


                                    <Typography variant="body2" color={"#fff"} fontWeight={"700"}>
                                        {(profileData?.name?.length > 0) ? profileData?.name?.split(" ")[0] : "Update Profile"}
                                    </Typography>

                                    <Typography variant="body2" color={"#fff"} fontWeight={"700"}>
                                        {profileData?.userId}
                                    </Typography>
                                </Box>
                                <IconButton onClick={handleProfileClick} style={{ marginLeft: "-3px" }}>
                                    <ExpandMore style={{ color: "#fff" }} />
                                </IconButton>

                            </>
                        )}
                    </Stack>
                    &nbsp; &nbsp; &nbsp;
                </>
            </Stack>



            <Popover
                id={profileId}
                open={openProfilePopover}
                anchorEl={anchorEl}
                onClose={handleProfileClose}
               
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <List sx={{ minWidth: 250, backgroundColor: "#000", borderRadius: "5px", color:"#fff" }}>
                    {profilePopoverMenu()?.map((menuItem, key) => (
                        <ListItem
                            disablePadding
                            key={key}
                            onClick={() => {
                                menuItem.onClick && menuItem.onClick();
                                navigate(menuItem.to);
                                setAnchorEl(null);
                                setMenuOption(menuItem?.title);
                            }}
                        >
                            <ListItemButton>
                                <ListItemIcon style={{color:"#fff"}}>{menuItem.icon}</ListItemIcon>
                                {menuItem?.withNumber ? (
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                        <ListItemText primary={menuItem.title} />{" "}
                                        <Box
                                            bgcolor={"#fff"}
                                            paddingLeft={12}
                                            paddingRight={12}
                                            paddingTop={6}
                                            paddingBottom={6}
                                            borderRadius={4}
                                            color={"#fff"}
                                        >
                                            {menuItem?.number}
                                        </Box>
                                    </Stack>
                                ) : (
                                    <ListItemText primary={menuItem.title} />
                                )}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Popover>

            <Dialog open={dialogOpen} onClose={handleDialogClose} sx={{
                '& .MuiPaper-root': {
                    borderRadius: "10px solid #000",
                    width: "100%"
                },
            }}>
                <DialogTitle>Update Profile :  {profileData?.userId}</DialogTitle>
                <DialogContent>

                    <DialogContentText sx={{ marginBottom: "2rem" }}>
                        To update your profile, please edit the fields below.
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        placeholder="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    {/* <PhoneNumberInput phoneNumber={profileData?.phone} /> */}
                    {/* <EmailInput profileEmail={profileData?.email || null} setEmail={updateEmail} email={email} /> */}

                    {(mpinFlag) ?
                        <Button
                            variant="outline-primary"
                            style={{
                                width: '100%',
                                padding: '10px',
                                fontWeight: '700',
                                borderRadius: '8px',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.2s ease-in-out',
                                disabled: true,
                                backgroundColor: "#000",
                                color: "#fff",
                                marginTop: "1rem",
                            }}
                            onClick={handleToggle}
                        >
                            {(mpinStatus) ? "Change MPIN" : "Set MPIN"}
                        </Button>
                        :
                        <Stack
                            direction="row" // Align children horizontally
                            spacing={2} // Add spacing between items
                            sx={{ display: "flex", alignItems: "center" }} // Center vertically
                        >
                            <Box sx={{ width: "70%" }}>
                                <MuiOtpInput
                                    value={mpin}
                                    onChange={handleChangeMpin}
                                    marginTop="1rem"
                                    length={4} // Customize based on MPIN length
                                    sx={{
                                        "& .MuiInputBase-input": {
                                            display: "flex",
                                            alignItems: "center", // Center input content
                                        },
                                    }}
                                />
                            </Box>

                            <Box sx={{ width: "30%" }}>
                                <Button
                                    variant="outlined"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "8px",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        backgroundColor: "#000",
                                        color: "#fff",
                                        marginTop: "1rem",
                                    }}
                                    onClick={handleSetMpin}
                                >
                                    Update MPIN
                                </Button>
                            </Box>
                        </Stack>
                    }







                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} sx={{ color: "#fff" }}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={loading} sx={{ color: "#ff" }}>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogBrokerOpen} onClose={handleDialogBrokerClose} sx={{
                '& .MuiPaper-root': {
                    backgroundColor: '#000000', // Your custom background color
                    borderRadius: "2px solid #000",
                },
            }}>
                <DialogTitle>Broker Connection</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        Add Broker : &nbsp;
                        <select value={selectedValue} onChange={handleChange1} style={{ padding: "0.5vh", fontWeight: "900" }}>
                            {/* <option value="" disabled></option> */}
                            <option value="option1">Zerodha</option>
                            <option value="option2">Upstox</option>
                        </select>
                    </DialogContentText>
                    <br />

                    <TextField
                        margin="dense"
                        id="apiKey"
                        label="API Key"
                        placeholder="API Key"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.apiKey}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        margin="dense"
                        id="apiSecret"
                        label="API Secret"
                        placeholder="API Secret"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.apiSecret}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <br />
                    <br />

                    <Typography>
                        Redirect Url : {" "} http://localhost:3000/OptionChain
                    </Typography>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogBrokerClose} sx={{ color: "#fff" }}>Cancel</Button>
                    <Button onClick={handleSubmitBroker} disabled={loading} sx={{ color: "#fff" }}>
                        Connect Broker
                    </Button>

                </DialogActions>
            </Dialog>


        </>
    );
}

export default IconMenu;
