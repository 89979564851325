import React, { useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const TickerTape = () => {
  // Example Data: Replace with your actual data
  const data = [
    { name: "Alice", age: 25 },
    { name: "Bob", age: 30 },
    { name: "Charlie", age: 35 },
    { name: "David", age: 28 },
    { name: "Eve", age: 22 },
    { name: "Frank", age: 40 },
    { name: "Grace", age: 32 },
    { name: "Hannah", age: 27 },
    { name: "Ian", age: 29 },
    { name: "Julia", age: 26 },
    { name: "Kevin", age: 33 },
    { name: "Laura", age: 31 },
    { name: "Mike", age: 38 },
    { name: "Nina", age: 24 },
    { name: "Oscar", age: 37 },
    { name: "Paul", age: 34 },
    { name: "Quincy", age: 23 },
    { name: "Rachel", age: 36 },
    { name: "Steve", age: 39 },
    { name: "Tina", age: 21 },
  ];

  const [tickerData, setTickerData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://cdn-python-api.chartinghq.com//ticker");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result.data) {
        const stocksData = Object.keys(result.data);

        const { pos, neg } = stocksData?.reduce(
          (acc, el) => {
            if (result?.data[el]?.change >= 0) {
              acc.pos += 1;
            } else {
              acc.neg += 1;
            }
            return acc;
          },
          { pos: 0, neg: 0 }
        );

        localStorage.setItem("ADRatio", JSON.stringify({ pos, neg }));
    
        let newResult = [];
        let names = Object.keys(result?.data);

        for (let i = 0; i < names.length; i++) {
          newResult.push({ name: names[i], change: result?.data[names[i]]?.change, lp: result?.data[names[i]]?.lp });
        }
  
        setTickerData(newResult);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("Ticker data fetched!");
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, []);


  return (
    <>

      {(!loading) ?
        <Box
          sx={{
            display: 'flex',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            backgroundColor: '#000',
            // borderRadius: 2,
            padding: 0.5,
            // boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
            position: 'relative',
            alignItems: 'center',  // Ensure content is vertically centered

          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              animation: 'scroll 140s linear infinite',
            }}
          >
            {/* Duplicate the data to make it seamless */}
            {[...tickerData, ...tickerData].map((item, index) => (
              <Box
                key={index}
                component="span"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center', // Vertically center the content
                  justifyContent: "center",
                  marginRight: 3,
                  fontWeight: '400',
                  fontSize: '0.9rem',
                  color: '#fff',
                  textAlign: 'center', // Center the text horizontally

                }}
              >

                <Box sx={{ marginRight: 1 }}>
                  {item.name}
                </Box>

                <span
                  style={{
                    color: item.change >= 0 ? '#02d372' :  '#ff7878',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    // paddingTop: '1px',  
                  }}
                >
                  {item.lp}
                  <span
                    style={{
                      // marginLeft: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      // paddingTop: '1px', // Fine-tune this padding if the arrows still seem off
                    }}
                  >
                    {item.change >= 0 ? (
                      <TiArrowSortedUp fontSize="1rem" />
                    ) : (
                      <TiArrowSortedDown fontSize="1rem" />
                    )}
                  </span>
                </span>

              </Box>
            ))}
          </Box>
          <style>
            {`
          @keyframes scroll {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-50%);
            }
          }
        `}
          </style>
        </Box>
        :
        // <Typography>Loading...</Typography>
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        // <Skeleton />
      }
    </>
  );
};


export default TickerTape;
