import React, { Suspense, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Navbar from "../Common/Navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorBoundary from "hooks/errorBoundary";
import TickerTape from "Components/Common/TickerTape2";
import { useOptionChainData } from "services/dataHub";
import { useDispatch } from "react-redux";
import SkeletonLoading from "Components/Common/Skeletons/OptionChain";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Dashboard = React.lazy(() => import("../Screens/Dashboard"));
const TradersRoom = React.lazy(() => import("./TradersRoom"));
const OptionChain = React.lazy(() => import("./OptionChain"));
const MultiOI = React.lazy(() => import("./MultiOi"));


const usePollingData = () => {
  const dispatch = useDispatch();
  const dataStateNifty = useOptionChainData({ symbol: "NIFTY", time: "1min" });
  const dataStateBankNifty = useOptionChainData({ symbol: "BANKNIFTY", time: "1min" });
  const dataStateFinNifty = useOptionChainData({ symbol: "FINNIFTY", time: "1min" });

  const fetchData = () => {
    
    if (!dataStateNifty.loading && dataStateNifty.data) {
      dispatch({
        type: "NIFTYDATA",
        payload: { dataStateNifty },
      });
    }
    if (!dataStateBankNifty.loading && dataStateBankNifty.data) {
      dispatch({
        type: "BANKNIFTYDATA",
        payload: { dataStateBankNifty },
      });
    }
    if (!dataStateFinNifty.loading && dataStateFinNifty.data) {
      dispatch({
        type: "FINNIFTYDATA",
        payload: { dataStateFinNifty },
      });
    }
  };


  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 3000);
    return () => clearInterval(intervalId);
  }, [dataStateNifty, dataStateBankNifty, dataStateFinNifty]);

};


const Main = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [navbarHeight, setNavbarHeight] = useState(0);

  usePollingData();


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#E8E8EA",
        height: (smDown) ? "100%" : "100vh",
      }}
    >
      {(smDown) ? <>
        <Box sx={{ zIndex: 1, borderBottom:"0.12rem solid #fff" }}>
          <TickerTape />
        </Box>

        <Box sx={{ marginBottom: "0.04rem", zIndex: 1 }}>
          <Navbar setNavbarHeight={setNavbarHeight} />
        </Box>

      </> :
        <>
          <Box sx={{ marginBottom: "0.04rem", zIndex: 1 }}>
            <Navbar setNavbarHeight={setNavbarHeight} />
          </Box>

          <Box sx={{ zIndex: 1 }}>
            <TickerTape />
          </Box>
        </>
      }


      <Box
        sx={{
          overflowY: "auto",
          justifyContent:"display",
          alignContent:"center",
          padding:"1rem",
        }}
      >
        <Suspense fallback={<SkeletonLoading />}>
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/OptionChain/*" element={<OptionChain />} />
              <Route path="/MultiOI/*" element={<MultiOI />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="/TradersRoom" element={<TradersRoom />} />
            </Routes>
          </ErrorBoundary>
        </Suspense>
      </Box>
    </Box>
  );
};

export default Main;
