import React from "react";
import SignInSignUp from "./SignInSignUp";
import { Grid } from "@mui/material";
const Auth = () => {
    
  return (
    <Grid
      container
      sx={{ display: "flex", justifyContent: "space-between"}}
    >
     <SignInSignUp/>
    </Grid>
  );
};

export default Auth;

