import { useState } from "react";

export function useApi(APIPromise) {
  const [loading, setLoading] = useState(false);

  const callAPI = (data) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      APIPromise(data)
        .then((res) => {
          resolve(res);
          setLoading(false);
        })
        .catch((err) => {
          reject(err);
          setLoading(false);
        });
    });
  };

  return [loading, callAPI];
}
