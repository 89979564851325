import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";

const theme = createTheme({
  palette,
  typography,
  components: {

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px", 
          textTransform: "none", 
          padding: "0.1rem 0.8rem 0.1rem 0.8rem",
          fontSize: "0.8rem", 
        },
      },
      defaultProps: {
        disableElevation: true, 
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: palette.primary.main,
            color: "#fff",
            "&:hover": {
              backgroundColor: palette.primary.dark,
            },
          },
        },
        {
          props: { variant: "success" },
          style: {
            backgroundColor: "#4CAF50",
            color: "#fff",
            "&:hover": {
              backgroundColor: palette.primary.dark,
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderColor: palette.primary.main,
            color: palette.primary.main,
            backgroundColor: palette.primary.light,
            border:"1.5px solid #000",
            "&:hover": {
              borderColor: palette.primary.light,
              border:"1.5px solid #000",
              backgroundColor: palette.primary.dark,
              color: palette.primary.light,
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            color: palette.secondary.main,
            "&:hover": {
              backgroundColor: palette.secondary.light,
            },
          },
        },
      ],
    },

    MuiGrid: {
      styleOverrides: {
        root: {
        //  width:"100%",
        //  height:"100%",
        },
        item: {
         
        },
      },
      variants: [
        {
          props: { variant: "main" },
          style: {
            padding: "0.4rem",
            backgroundColor: "#fff",
            borderRadius: "0.4rem",
          },
        },
        {
          props: { variant: "centered" },
          style: {
            justifyContent: "center", // Center align items
            alignItems: "center",
          },
        },
        {
          props: { variant: "fullWidth" },
          style: {
            width: "100%", // Make grid items take full width
          },
        },
      ],
  },

  },
});

export default theme;
