const initialState = {
  profileData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_USER_PROFILE":
      return {
        ...state,
        profileData: { ...state.profileData, ...action.payload },
      };
    default:
      return state;
  }
}
