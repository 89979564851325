import { ResponseHandler } from "./ResponseHandlers";

export const createUser = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "/api/users/createUser",
    method: "POST",
    data,
    params,
  });

export const registerUser = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/auth/signup",
    method: "POST",
    data,
    headers,
    params,
  });

export const verifyPhoneNumber = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/otp/sendPhoneOtp",
    method: "POST",
    data,
    headers,
    params,
  });

export const verifyEmail = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/otp/verifyEmailOtp",
    method: "POST",
    data,
    headers,
    params,
  });

export const loginUser = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/auth/signin",
    method: "POST",
    data,
    headers,
    params,
  });

export const logoutUser = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/auth/logout",
    method: "GET",
    data,
    headers,
    params,
  });

export const thirdPartyLogin = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "/api/users/thirdPartyLogin",
    method: "POST",
    data,
    headers,
    params,
  });

export const forgotPassword = ({
  data,
  headers,
  params
}) => {
  ResponseHandler({
    endpoint: "/api/users/forgotPassword",
    method: "POST",
    data,
    headers,
    params,
  });
};

export const updatePassword = ({
  data,
  headers,
  params
}) => {
  ResponseHandler({
    endpoint: "/api/users/updatePassword",
    method: "POST",
    data,
    headers,
    params,
  });
};

export const getUser = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/auth/getUser",
    method: "GET",
    data,
    headers,
    params,
  });

export const payAPI = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/api/subscribe",
    method: "POST",
    data,
    headers,
    params,
  });

export const cancelAutoRenewal = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "/api/cancel-subscription",
    method: "POST",
    data,
    headers,
    params,
  });

export const verifyOtp = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/otp/verifyPhoneOtp",
    method: "POST",
    data,
    headers,
    params,
  });

export const sendOTP = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/otp/sendPhoneOtp",
    method: "POST",
    data,
    headers,
    params,
  });

export const sendEmailOtp = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/otp/sendEmailOtp",
    method: "POST",
    data,
    headers,
    params,
  });

export const updateProfile = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "https://api-test.chartinghq.com/auth/update",
    method: "POST",
    data,
    headers,
    params,
  });

export const connectTerminal = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: "/api/users/connectTerminal",
    method: "POST",
    data,
    headers,
    params,
  });

export const optionChain = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://cdn-python-api.chartinghq.com//option_chain?symbol=${data.symbol}&timeframe=${data.time}`,
    method: "GET",
    data,
    headers,
    params,
  });

export const lastOptionChain = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://cdn-python-api.chartinghq.com//last_option_chain?symbol=${data.symbol}&timeframe=${data.time}min`,
    method: "GET",
    data,
    headers,
    params,
  });

export const spotData = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://cdn-python-api.chartinghq.com//spot_data?symbol=${data.symbol}&timeframe=${data.time}`,
    method: "GET",
    data,
    headers,
    params,
  });

export const getSignal = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://cdn-python-api.chartinghq.com//get_signal`,
    method: "GET",
    data,
    headers,
    params,
  });

export const getLastPcr = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://cdn-python-api.chartinghq.com/last_pcr_data`,
    method: "GET",
    data,
    headers,
    params,
  });

export const placeOrder = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/placeOrder`,
    method: "POST",
    data,
    headers,
    params,
  });

  export const cancelOrder = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/cancelOrder`,
    method: "POST",
    data,
    headers,
    params,
  });
  

export const generateAccessToken = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/generateAccessToken`,
    method: "POST",
    data,
    headers,
    params,
  });

  export const callBack = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/callBack`,
    method: "POST",
    data,
    headers,
    params,
  });

export const getOrderTrades = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/getOrderTrades`,
    method: "POST",
    data,
    headers,
    params,
  });

export const upstoxLogin = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `http://localhost:5050/api/upstox/upstoxLogin`,
    method: "POST",
    data,
    headers,
    params,
  });

export const addBroker = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/addBroker`,
    method: "POST",
    data,
    headers,
    params,
  });

export const myBrokers = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/myBrokers`,
    method: "GET",
    data,
    headers,
    params,
  });

export const brokerList = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/brokersList`,
    method: "GET",
    data,
    headers,
    params,
  });

  export const  editBroker = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/editBroker`,
    method: "POST",
    data,
    headers,
    params,
  });

  export const deleteBroker = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/deleteBroker`,
    method: "POST",
    data,
    headers,
    params,
  });

  export const disconnectBroker = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/brokers/disconnectBroker`,
    method: "POST",
    data,
    headers,
    params,
  });

  export const changeMpin = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/auth/setMpin`,
    method: "POST",
    data,
    headers,
    params,
  });

  export const verifyMpin = ({
    data,
    headers,
    params
  }) =>
  ResponseHandler({
    endpoint: `https://api-test.chartinghq.com/auth/verifyMpin`,
    method: "GET",
    data,
    headers,
    params,
  });

  
