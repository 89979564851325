const palette = {
    primary: {
      main: "#000",
      dark: "#3b444b",
      light: "#FFF",
    },
    secondary: {
      main: "#000",
      light: "#fff",
    },
    
  };
  
  export default palette;
  