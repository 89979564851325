const initialState = {
  menuOption: { data: "notifications" },
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "MENU_OPTION":
      return {
        ...state,
        menuOption: action.payload,
      };
    default:
      return state;
  }
}
