
const typography = {
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  h1: {
    fontSize: "2.25rem", // Default size
    fontWeight: 700,
    lineHeight: 1.5,
    "@media (max-width:600px)": {
      fontSize: "1.75rem", // Responsive size for small screens
    },
  },
  h2: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.5,
    "@media (max-width:600px)": {
      fontSize: "1.5rem",
    },
  },
  h3: {
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: 1.4,
    "@media (max-width:600px)": {
      fontSize: "1.25rem",
    },
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.4,
    "@media (max-width:600px)": {
      fontSize: "1.125rem",
    },
  },
  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.4,
    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.4,
    "@media (max-width:600px)": {
      fontSize: "0.875rem",
    },
  },
  subtitle1: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.6,
    color: "#333",
  },
  body2: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.6,
    color: "#555",
  },
  button: {
    fontSize: "0.875rem",
    fontWeight: 600,
    textTransform: "none",
  },
  caption: {
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.3,
  },
  overline: {
    fontSize: "0.75rem",
    fontWeight: 400,
    textTransform: "uppercase",
    lineHeight: 1.3,
  },
};

export default typography;
