import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import {thunk} from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import debounce from "lodash.debounce";
import { batchedSubscribe } from "redux-batched-subscribe";


import strikeData from "./reducers/strikeData";
import userProfile from "./reducers/userProfile";
import menustate from "./reducers/menustate";
import stateManager from "./reducers/stateManager";
import pcr from "./reducers/pcr";
import bankNiftyData from "./reducers/bankNiftyData";
import niftyData from "./reducers/niftyData";
import finNiftyData from "./reducers/finNiftyData";
import connectedBroker from "./reducers/connectedBroker";
import  spotData  from "./reducers/spotData";

const debounceNotify = debounce((notify) => notify(), 50);

const rootReducer = combineReducers({
  bankNiftyData,
  strikeData,
  userProfile,
  menustate,
  stateManager,
  pcr,
  niftyData,
  finNiftyData,
  connectedBroker,
  spotData
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userProfile", "stateManager"], // Specify reducers to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(thunk),
    batchedSubscribe(debounceNotify)
  )
);

const persistor = persistStore(store);

export { store, persistor }; // Named exports
