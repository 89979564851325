import React from "react";
import { Grid, Box, Skeleton } from "@mui/material";

const OptionChainSkeleton = () => {
  return (
    <Grid container sx={{display:"flex", justifyContent:"space-between", padding:"1rem"}} spacing={1}>
      <Grid item lg={8}>
      <Grid container sx={{display:"flex", justifyContent:"space-between"}} spacing={1}>
      <Grid item lg={12}>
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={50}
          sx={{ borderRadius: 1 }}
        />
       </Grid>

       <Grid item lg={12}>
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={350}
          sx={{ borderRadius: 1 }}
        />
       </Grid>

       <Grid item  lg={12}>
       <Grid container sx={{display:"flex", justifyContent:"space-between"}} spacing={1}>
      <Grid item lg={6}>
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={230}
          sx={{ borderRadius: 1 }}
        />
       </Grid>

       <Grid item lg={6}>
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={230}
          sx={{ borderRadius: 1 }}
        />
       </Grid>

     

      </Grid>
       </Grid>

      </Grid>
      </Grid>

      <Grid item lg={4} >
      <Skeleton
          variant="rectangular"
          width={"100%"}
          height={650}
          sx={{ borderRadius: 1 }}
        />
      </Grid>

      <Grid item lg={12} >
      <Skeleton
          variant="rectangular"
          width={"100%"}
          height={40}
          sx={{ borderRadius: 1 }}
        />
      </Grid>

    </Grid>
  );
};

export default OptionChainSkeleton;
