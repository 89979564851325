const initialState = {
  data: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "CONNECTEDBROKER":
      return {
        ...state,
       data: action.payload,
      };
    default:
      return state;
  }
}
