import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { getUser, optionChain, spotData, getSignal, lastOptionChain, getLastPcr} from "services/apis/newApis";
import { useApi } from "hooks/useAPI";

const useSessionValidation = () => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [authanticated, setAuthanticated] = useState(false);
  // const [loading1, callAPI1] = useApi(optionChain);
  // const [loading2, callAPI2] = useApi(spotData);
  // const [loading3, callAPI3] = useApi(getSignal);
  // const [loading4, callAPI4] = useApi(getLastPcr);
  // const [loading5, callAPI5] = useApi(lastOptionChain);

  // const sendMessage = () => {
  //      setTimer(timer-1);
  //     console.log("Message Send",ws);
  //     socket.send(JSON.stringify({
  //       message:"hey I am from Reactjs",
  //       time : timer
  //     }));
  // };

  // useEffect(() => {
  //     socket.onopen = () => {
  //     console.log('Connected to WebSocket server');
  //     sendMessage();

  //     const interval = setInterval(() => {
  //       sendMessage(value);
  //     }, 1000); 
      

  //     return () => clearInterval(interval);
  //   };
  
  //   socket.onerror = (error) => {
  //     console.error('WebSocket error:', error);
  //   };
  
  //   socket.onmessage = (event) => {
  //     console.log('Message from server:', event.data);
  //   };
  
  //   socket.onclose = () => {
  //     console.log('Disconnected from WebSocket server');
  //   };
  
  //   return () => socket.close(); // Cleanup when the component unmounts
  // }, []);

  // const DataCenter = async (symbol, time) => {

  //    await callAPI1({
  //     data: {
  //       symbol: symbol,
  //       time: time,
  //     },
  //   })
  //     .then((res) => {
    
  //       if (symbol === "NIFTY") {
          
  //         dispatch({
  //           type: 'NIFTYDATA',
  //           payload: { data: JSON.stringify(res?.data) },
  //         });

  //         const names = Object.keys(res?.data);
  //         localStorage.setItem("optionNamesNifty", JSON.stringify(names));
  //       }
  //       else if (symbol === "BANKNIFTY") {
          
  //         dispatch({
  //           type: 'BANKNIFTYDATA',
  //           payload: { data: JSON.stringify(res?.data) },
  //         });
  //         const names = Object.keys(res?.data);
  
  //         localStorage.setItem("optionNamesBankNifty", JSON.stringify(names));
  //       }
  //       else if (symbol === "FINNIFTY") {
         
  //         dispatch({
  //           type: 'FINNIFTYDATA',
  //           payload: { data: JSON.stringify(res.data)},
  //         });

  //         const names = Object.keys(res?.data);
  //         localStorage.setItem("optionNamesFinNifty", JSON.stringify(names));
  //       }

  //       else {
  //         // console.log("SYMBOL",symbol);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.message === 'Network Error' || error.code === 'ERR_NETWORK_CHANGED') {
  //         // Retry after 5 seconds if network changes
  //         setTimeout(() => fetch(), 1000);
  //       } else {
  //         console.error('API request failed:', error);
  //       }
  //     });


  //     await callAPI5({
  //       data: {
  //         symbol: symbol,
  //         time: time,
  //       },
  //     })
  //       .then((res) => {
      
  //         if (symbol === "NIFTY") {
  //           dispatch({
  //             type: 'LASTNIFTYDATA',
  //             payload: { data: JSON.stringify(res?.data) },
  //           });
  //           const names = Object.keys(res?.data);
  //           localStorage.setItem("optionNamesNifty", JSON.stringify(names));
  //         }
  //         else if (symbol === "BANKNIFTY") {
  //           dispatch({
  //             type: 'LASTBANKNIFTYDATA',
  //             payload: { data: JSON.stringify(res?.data) },
  //           });
  //           const names = Object.keys(res?.data);
  //           // localStorage.setItem("optionNamesBankNifty", JSON.stringify(names));
  //         }
  //         else if (symbol === "FINNIFTY") {
           
  //           dispatch({
  //             type: 'LASTFINNIFTYDATA',
  //             payload: { data: JSON.stringify(res.data)},
  //           });
  
  //           const names = Object.keys(res?.data);
  //           localStorage.setItem("optionNamesFinNifty", JSON.stringify(names));
  //         }
  
  //         else {
  //           // console.log("SYMBOL",symbol);
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.message === 'Network Error' || error.code === 'ERR_NETWORK_CHANGED') {
  //           // Retry after 5 seconds if network changes
  //           setTimeout(() => fetch(), 1000);
  //         } else {
  //           console.error('API request failed:', error);
  //         }
  //       });
  // }


  // const getSignalData = async () => {
  //  callAPI3({
  //     data: {
  //       symbol: "NIFTY",
  //       time: 1,
  //     },
  //   })
  //     .then((res) => {
  //       // console.log(res,"res");
  //       localStorage.setItem("signal", JSON.stringify(res.data));
  //     })
  //     .catch((error) => {
  //       if (error.message === 'Network Error' || error.code === 'ERR_NETWORK_CHANGED') {
  //         // Retry after 5 seconds if network changes
  //         setTimeout(() => fetch(), 1000);
  //       } else {
  //         console.error('API request failed:', error);
  //       }
  //     });

  // };

  // const getLastPCR = async()=>{
  //   callAPI4({
  //     data: {},
  //   })
  //     .then((res) => {
  //       // console.log(res,"res");
  //       localStorage.setItem("LastDayPcr", JSON.stringify(res));
  //      })
  //     .catch((error) => {
  //       if (error.message === 'Network Error' || error.code === 'ERR_NETWORK_CHANGED') {
  //         // Retry after 5 seconds if network changes
  //         setTimeout(() => fetch(), 1000);
  //       } else {
  //         console.error('API request failed:', error);
  //       }
  //     });
  // }

  // const fetch = async () => {
    // await DataCenter("NIFTY", 1);
    // await DataCenter("BANKNIFTY", 1);
    // await DataCenter("FINNIFTY", 1);

    // await getSignalData();
    // await getLastPCR();
  // }

  useEffect(() => {
    const token = localStorage.getItem("Authorization");   
    if (token) {
      getUser({})
        .then((res) => {
          setLoading(false);
          setAuthanticated(true);
         
          dispatch({
            type: "SET_USER_PROFILE",
            payload: res,
          });

          // enqueueSnackbar("Session validated", { variant: "success" });
        })
        .catch((err) => {

          enqueueSnackbar(err, { variant: "error" });
          console.log(err);

        })
        .finally(() => {
          setLoading(false);
        });

      //  fetch();

      // const intervalId = setInterval(fetch, 60000);
      // return () => clearInterval(intervalId);

    } else {
      setLoading(false);
    }

  }, []);

  return {
    loading,
    authanticated,
  };
};

export default useSessionValidation;
