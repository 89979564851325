import { useApi } from "hooks/useAPI";
import { optionChain } from "services/apis/newApis";
import { useEffect, useState } from "react";

export const useOptionChainData = ({ symbol, time }) => {

  const [optionChainDataApiLoading, optionChainDataApi] = useApi(optionChain);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  let isMounted = true;
  
  const fetchData = async () => {
    console.log("I am Called!! Bab");
    try {
        let response = await optionChainDataApi({
            data: { symbol, time },
        });

        if (typeof response === "string") {
            try {
                response = JSON.parse(response);
             
            } catch (error) {
                console.error("Failed to parse response:", error);
            }
        }
        if (isMounted) setData(response?.data);
    } catch (err) {
        if (isMounted) setError(err?.message || "API request failed");
    }
};

  useEffect(() => {
    if (symbol && time) fetchData();
    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    return () => {
        // isMounted = false; // Cleanup to avoid memory leaks
        clearInterval(intervalId);
    };
}, [symbol, time]);

  return { loading: optionChainDataApiLoading, data, error };
};
