import React, { useEffect } from "react";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./Themes";
import Main from "./Components/Main";
import { Provider } from "react-redux";
import { store, persistor } from "services/redux/store";
import { Box, CssBaseline } from "@mui/material";
import Auth from "Components/Auth";
import useSessionValidation from "hooks/useSessionValidation";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useApi } from "hooks/useAPI";
import { callBack } from "services/apis/newApis";
import Loader from "Components/Common/Loader";

function App() {
  const { loading, authanticated } = useSessionValidation();
  const [loadingCallBack, callBackApi] = useApi(callBack);

  useEffect(()=>{
    if (window.location.href.includes('connectbroker')) {
      let broker = JSON.parse(localStorage.getItem("broker"));
      const currentUrlSearch = window.location.search;
      const queryParams = new URLSearchParams(currentUrlSearch);
      const request_token = queryParams.get('request_token');
      const code = queryParams.get('code'); 
      
      if ((code || request_token) && broker) {
          callBackApi({
              data: {
                  broker: broker,
                  code: (broker?.brokerName === "Zerodha") ? request_token : code,
              }
          }).then((res) => {
              console.log(res,"res+++++++++");
          }
          )
              .catch((error) => {
                  console.log(error);
              })

      }

    }
   },[]);

  return (
    <React.Fragment>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Provider store={store}>
            <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
              <CssBaseline />
              {(loading)?<Loader/>:
              !authanticated ? (
                <Auth />
              ) : (
                <>
                  <Main />
                </>
              )}
            </PersistGate>
          </Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
